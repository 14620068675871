import { defineApi } from '../common/api-definition';
import { StructuredUtilisationType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type SequenceUtilisationView = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  utilisation: StructuredUtilisationType;
  currency: string | undefined;
  state: StructuredLoanState;

  // Set if this is a new sequence that can be amended freely.
  amendNewSequence: boolean;
};

export const sequenceUtilisationViewApi = defineApi<{
  body: { loanId: string; sequenceId: string };
  response: SequenceUtilisationView;
}>({
  url: 'structured-loans/sequence-utilisation-view',
  method: 'post',
});
