import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { StructuredLoanState } from './structured-loan-state';

export type StartSchedulesView = AvailableStartSchedulesView | UnavailableStartSchedulesView;

export type AvailableStartSchedulesView = CommonStartSchedulesView & {
  type: 'available';
  currency: string;
  tranches: (AvailableStartSchedulesTranche | NonUtilisedStartSchedulesTranche)[];
};

export type UnavailableStartSchedulesView = CommonStartSchedulesView & {
  type: 'unavailable';
  currency: string;
  tranches: UnavailableStartSchedulesTranche[];
};

export type CommonStartSchedulesView = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
  state: StructuredLoanState;
  version: number;

  /** The utilisation attributes that have been configured. */
  utilisation_date?: string;
  utilisation_amount?: number;

  /** True if this is a new sequence that can be amended freely. */
  amendNewSequence: boolean;
};

export type StartSchedulesTranche = AvailableStartSchedulesTranche | NonUtilisedStartSchedulesTranche;

/** A tranche that is not available because of errors in the loan configuration */
export type UnavailableStartSchedulesTranche = CommonStartSchedulesTranche & {
  type: 'unavailable';
};

/** A tranche for a sequence that has not been utilised. */
export type NonUtilisedStartSchedulesTranche = CommonStartSchedulesTranche & {
  type: 'non-utilised';
};

/** A tranche for which the schedule is known. */
export type AvailableStartSchedulesTranche = CommonStartSchedulesTranche & {
  type: 'available';

  /** The columns that should be shown */
  columns: {
    /** The subvention coverage is shown for terms with subvention enabled for the interest rate. */
    subventionCoverage: boolean;

    /** The interest rate is shown for terms with custom interest rates. */
    interestRate: boolean;

    /** The prepayment amount is shown for running loans. The prepayment amount is not shown
        for drafts, because the amounts are always empty for drafts and the amounts cannot
        be edited.
    */
    prepaymentAmount: boolean;
  };

  utilisationDate: string;
  utilisationAmount: number;

  periods: {
    periodIndex: number;
    isCurrentPeriod: boolean;
    valueDate: string;
    outstandingDebtStart: number;

    /** The subvention coverage is editable only when subvention is enabled. */
    subventionCoverage?: { value: number; isEditable: boolean };

    /** The interest rate is editable only custom interest rates. */
    interestRate?: { value: number | undefined; isEditable: boolean };

    /** The interest amount is never editable. It could be made editable for
        mortgage bond loans, but this is currently not supported in the LCE. */
    interestAmount: number;

    /** The amortisation amount is editable only for custom amortisations. It could be
        made editable for mortgage bond loans, but this is currently not supported
        in the LCE. */
    amortisationAmount: { value: number; isEditable: boolean };

    /** Prepayment amount cannot be edited (they must be set through the
        initiate prepayment modal). */
    prepaymentAmount: number | undefined;
  }[];

  /** The amount is set only for tranches with a pay back. */
  terminationPayBackPrincipalAmount?: number;

  totalInterestAmount: number;
  totalAmortisationAmount?: number;
  totalPrepaymentAmount: number;
};

export type CommonStartSchedulesTranche = {
  id: string;
  name: string;
};

export type StartSchedulesViewRequest = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
};

export const startSchedulesViewValidation = ObjectStripUnknown<StartSchedulesViewRequest>({
  loanId: NonEmptyString.required(),
  facilityId: NonEmptyString.required(),
  sequenceId: NonEmptyString.required(),
}).required();

export const startSchedulesViewApi = defineApi<{
  body: StartSchedulesViewRequest;
  response: StartSchedulesView;
}>({
  url: 'structured-loans/start-schedules-view',
  method: 'post',
});
