import { isNumber } from 'lodash';
import { InformationUndertakingSpecDraftType } from '../covenants/InformationUndertakingSpecType';
import { LoanMetricDraftType } from '../covenants/LoanMetricType';
import { SLReportingEventConfig, SLReportingEventConfigDraft } from '../covenants/ReportingEventConfigType';
import { NotFoundException } from '../utils/exceptions';
import { getLoanLevel as getLoanLevelHelper } from '../utils/get-loan-level';
import { defined } from '../utils/type-utils';
import { SLPropertyDetails } from './SLPropertyType';
import { StructuredAgreementDbType } from './structured-agreement-db-type.type';
import {
  StructuredAgreementType,
  StructuredConstructionType,
  StructuredFacilityType,
  StructuredLegalEntityType,
  StructuredSequenceType,
  StructuredTranchesType,
  StructuredUtilisationType,
} from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';
import { StructuredStartConfigurationType } from './structured-start-configuration-type';

const getLoanByState = (loan: StructuredAgreementDbType): StructuredAgreementType => {
  if (loan.state === StructuredLoanState.AMEND) {
    const amendedLoan = loan.data.amend?.loan;
    if (!amendedLoan) throw new Error('No data for amended loan');
    return amendedLoan;
  }
  return loan.data.loan;
};

const getTotalCommitment = (loan: StructuredAgreementType): number | undefined => {
  const commitments = loan.facilities.map(facility => getFacilityTotalCommitment(facility));
  return commitments.filter(isNumber).reduce((acc, x) => (acc ?? 0) + x, undefined as number | undefined);
};

const getFacilityTotalCommitment = (facility: StructuredFacilityType): number | undefined => {
  const commitments = facility.sequences.flatMap(sequence => [
    sequence.construction?.commitment,
    sequence.utilisations?.[0].commitment,
  ]);
  return commitments.filter(isNumber).reduce((acc, x) => (acc ?? 0) + x, undefined as number | undefined);
};

const getCollectionEvents = (loan: StructuredAgreementType): InformationUndertakingSpecDraftType[] =>
  loan.information_undertakings;

function getMetric(loan: StructuredAgreementType, metricId: string): LoanMetricDraftType {
  const metric = loan.loan_metrics.find(m => m.id === metricId);
  if (!metric) {
    throw new NotFoundException('Metric not found');
  }
  return metric;
}

const findFacility = (loan: StructuredAgreementType, id: string): StructuredFacilityType | undefined =>
  loan.facilities.find(f => f.id === id);

function getFacility(loan: StructuredAgreementType, id: string): StructuredFacilityType {
  const facility = findFacility(loan, id);
  if (!facility) throw new NotFoundException(`Facility with id "${id}" not found`);
  return facility;
}

function getFacilityBySequenceId(loan: StructuredAgreementType, sequenceId: string): StructuredFacilityType {
  const facilities = loan.facilities;
  for (const facility of facilities) {
    for (const sequence of facility.sequences) {
      if (sequence.id === sequenceId) return facility;
    }
  }
  throw new NotFoundException(`Facility of sequence "${sequenceId}" not found`);
}

const findSequence = (facility: StructuredFacilityType, id: string): StructuredSequenceType | undefined =>
  facility.sequences.find(s => s.id === id);

const findSequenceInLoan = (loan: StructuredAgreementType, sequenceId: string): StructuredSequenceType | undefined => {
  for (const facility of loan.facilities) {
    for (const sequence of facility.sequences) {
      if (sequence.id === sequenceId) return sequence;
    }
  }
  return undefined;
};

function getSequence(facility: StructuredFacilityType, id: string): StructuredSequenceType {
  const sequence = findSequence(facility, id);
  if (!sequence) throw new NotFoundException(`Sequence with id "${id}" not found`);
  return sequence;
}

function getSequenceInLoan(loan: StructuredAgreementType, sequenceId: string): StructuredSequenceType {
  const sequence = findSequenceInLoan(loan, sequenceId);
  if (!sequence) throw new NotFoundException(`Sequence "${sequenceId}" not found`);
  return sequence;
}

/**
 * True if sequence `sequenceId` has been prepared as an amendment but has not been committed yet.
 *
 * If the sequence does not exist neither as an amendment nor as part of the committed loan, then
 * an internal error is thrown.
 */
const isNewAmendmentSequence = (dbLoan: StructuredAgreementDbType, sequenceId: string): boolean => {
  const sequence = findSequenceInLoan(getLoanByState(dbLoan), sequenceId);
  if (!sequence) {
    throw new Error(`Sequence "${sequenceId}" is neither new nor existing.`);
  }
  return loanConfig.findSequenceInLoan(dbLoan.data.loan, sequenceId) === undefined;
};

function getAllSequencesIds(loan: StructuredAgreementType): string[] {
  const facilities = loan.facilities;
  const sequencesIds: string[] = [];
  for (const facility of facilities) {
    for (const sequence of facility.sequences) {
      sequencesIds.push(sequence.id);
    }
  }
  return sequencesIds;
}

/** N.B.: Not valid for lookup of construction tranches. */
const findTranche = (sequence: StructuredSequenceType, id: string): StructuredTranchesType | undefined =>
  sequence.tranches.find(t => t.id === id);

/** N.B.: Not valid for lookup of construction tranches. */
function getTranche(sequence: StructuredSequenceType, id: string): StructuredTranchesType {
  const tranche = findTranche(sequence, id);
  if (!tranche) throw new NotFoundException(`Tranche with id "${id}" not found`);
  return tranche;
}

const getAllTranches = (sequence: StructuredSequenceType): (StructuredConstructionType | StructuredTranchesType)[] => {
  // For some reason the construction terms are stored separately from the other
  // terms. This means that if you want to look up any kind of tranche, you need
  // to look in two different places.
  const { construction } = sequence;
  if (construction) {
    return [construction, ...sequence.tranches];
  }
  return sequence.tranches;
};

const findConstructionOrRegularTranche = (
  sequence: StructuredSequenceType,
  id: string
): StructuredConstructionType | StructuredTranchesType | undefined =>
  getAllTranches(sequence).find(tranche => tranche.id === id);

const getConstructionOrRegularTranche = (
  sequence: StructuredSequenceType,
  id: string
): StructuredConstructionType | StructuredTranchesType => {
  const tranche = findConstructionOrRegularTranche(sequence, id);
  if (!tranche) throw new NotFoundException(`Tranche with id "${id}" not found`);
  return tranche;
};

const getAgentId = (loanAgreement: StructuredAgreementType) => loanAgreement.agent;

const getBorrowerId = (loanAgreement: StructuredAgreementType) => loanAgreement.borrower_company_id;

/**
 * The lead borrower is an SPV that is created to mitigate loan risks.
 */
function getLeadBorrower(loan: StructuredAgreementType): {
  leadBorrower: StructuredLegalEntityType | undefined;
  isFund: boolean;
} {
  if (loan.legal_entity == null) {
    return { leadBorrower: undefined, isFund: false };
  }

  const entity = loan.legal_entity;

  // Legacy. If it doesn't have a tag, it's always a fund.
  if (entity.type == null || entity.type === 'fund') {
    return { leadBorrower: entity, isFund: true };
  }

  if (entity.type === 'facility-link') {
    return { leadBorrower: loan.facilities.find(f => f.id === entity.facility_id)?.legal_entity, isFund: false };
  }

  // Can't get here, but typescript thinks its possible
  return { leadBorrower: undefined, isFund: false };
}

const getFund = (loan: StructuredAgreementType): StructuredLegalEntityType | undefined => {
  const leadBorrower = getLeadBorrower(loan);
  return leadBorrower.isFund ? leadBorrower.leadBorrower : undefined;
};

const getReportingEvent = (
  loan: StructuredAgreementType,
  reId: string
): SLReportingEventConfigDraft | SLReportingEventConfig => {
  for (const re of loan.reporting_events) {
    if (re.id === reId) return re;
  }
  throw new NotFoundException(`Reporting event with id ${reId} not found`);
};

const lenderIds = (loan: StructuredAgreementType) => loan.cap_table.map(i => i.lender);

const isStarted = (state: StructuredLoanState) =>
  [StructuredLoanState.ABORTED, StructuredLoanState.AMEND, StructuredLoanState.RUNNING].includes(state);

const isRunning = (state: StructuredLoanState) =>
  [StructuredLoanState.RUNNING, StructuredLoanState.AMEND].includes(state);

function getProperty(loan: StructuredAgreementType, propertyId: string): SLPropertyDetails {
  const facilities = loan.facilities;
  for (const facility of facilities) {
    for (const prop of facility.properties) {
      if (prop.id === propertyId) return prop;
    }
  }
  throw new NotFoundException(`Property "${propertyId}" not found`);
}

function getPropertyOfFacility(facility: StructuredFacilityType, propertyId: string): SLPropertyDetails {
  const property = facility.properties.find(p => p.id === propertyId);
  if (!property) {
    throw new NotFoundException(`Property "${propertyId}" not found in facility "${facility.id}"`);
  }
  return property;
}

function getStartConfigurationByState(dbLoan: StructuredAgreementDbType): StructuredStartConfigurationType {
  if (dbLoan.state === StructuredLoanState.AMEND) {
    const amendedLoan = dbLoan.data.amend?.start_configuration;
    if (!amendedLoan) throw new Error('No data for amended loan!');
    return amendedLoan;
  }
  return dbLoan.data.start_configuration;
}

function getOriginalLendersIds(loan: StructuredAgreementType): string[] {
  return loan.cap_table?.map(cap => cap.lender) || [];
}

function getTrancheGovernmentIds(loan: StructuredAgreementType): string[] {
  const governmentIds: string[] = [];
  loan.facilities?.forEach(facility => {
    facility.sequences?.forEach(sequence => {
      sequence.tranches?.forEach(tranche => {
        const governmentId = tranche.subvention?.government;
        if (governmentId) {
          governmentIds.push(governmentId);
        }
      });
    });
  });
  return governmentIds;
}

function getConstructionGovernmentIds(loan: StructuredAgreementType): string[] {
  const governmentIds: string[] = [];
  loan.facilities?.forEach(facility => {
    facility.sequences?.forEach(sequence => {
      const governmentId = sequence.construction?.subvention?.government;
      if (governmentId) {
        governmentIds.push(governmentId);
      }
    });
  });
  return governmentIds;
}

function getGovernmentIds(loan: StructuredAgreementType): string[] {
  return [...new Set([...getTrancheGovernmentIds(loan), ...getConstructionGovernmentIds(loan)])];
}

function getLoanParties(loan: StructuredAgreementType): string[] {
  return [getAgentId(loan), getBorrowerId(loan), ...getOriginalLendersIds(loan), ...getGovernmentIds(loan)].filter(
    defined
  );
}

function getNocParties(loan: StructuredAgreementType): string[] {
  return [getAgentId(loan), getBorrowerId(loan), ...getOriginalLendersIds(loan), ...getGovernmentIds(loan)]
    .filter(id => id !== loan.owner_company_id)
    .filter(defined);
}

function isOwnedByCompany(loan: StructuredAgreementType, companyId: string): boolean {
  return loan.owner_company_id === companyId;
}

function getLevelFromSequence(loan: StructuredAgreementType, sequenceId?: string): string {
  if (!sequenceId) return 'Loan Agreement';
  const facility = getFacilityBySequenceId(loan, sequenceId);
  const sequence = getSequenceInLoan(loan, sequenceId);
  const initialLevel = 'Loan Agreement';
  const level = facility?.name ?? initialLevel;
  return level !== initialLevel && sequence?.name ? `${level} / ${sequence?.name}` : initialLevel;
}

function getLoanLevel(loan: StructuredAgreementType, sequenceId: string): string {
  const facilityName = getFacilityBySequenceId(loan, sequenceId).name;
  const sequenceName = getSequenceInLoan(loan, sequenceId).name;
  return getLoanLevelHelper(facilityName, sequenceName);
}

function getLevel(loan: StructuredAgreementType, facilityId: string, sequenceId: string): string {
  if (!sequenceId || !facilityId) return 'Loan Agreement';
  const facility = getFacility(loan, facilityId);
  const sequence = getSequenceInLoan(loan, sequenceId);
  const initialLevel = 'Loan Agreement';
  const level = facility?.name ?? initialLevel;
  return level !== initialLevel && sequence?.name ? `${level} / ${sequence?.name}` : initialLevel;
}

function getInformationUndertaking(loan: StructuredAgreementType, iuId: string): InformationUndertakingSpecDraftType {
  for (const iu of loan.information_undertakings) {
    if (iu.id === iuId) return iu;
  }
  throw new NotFoundException('Information undertaking not found!');
}

function getCommitmentAmount(loan: StructuredAgreementType, sequenceId: string): number {
  const sequence = getSequenceInLoan(loan, sequenceId);
  return sequence.construction?.commitment ?? sequence.utilisations?.[0].commitment ?? 0;
}

function getConstructionTerms(loan: StructuredAgreementType, constructionId: string): StructuredConstructionType {
  const facilities = loan.facilities;

  for (const facility of facilities) {
    for (const sequence of facility.sequences) {
      if (sequence.construction?.id === constructionId) return sequence.construction;
    }
  }
  throw new NotFoundException('Construction tranche not found!');
}

function getTerms(loan: StructuredAgreementType, termsId: string): StructuredTranchesType {
  const facilities = loan.facilities;

  for (const facility of facilities) {
    for (const sequence of facility.sequences) {
      for (const termsTranch of sequence.tranches) {
        if (termsTranch.id === termsId) return termsTranch;
      }
    }
  }
  throw new NotFoundException('Term tranche not found!');
}

function getUtilisation(loan: StructuredAgreementType, utilisationId: string): StructuredUtilisationType {
  const facilities = loan.facilities;

  for (const facility of facilities) {
    for (const sequence of facility.sequences) {
      if (sequence.utilisations) {
        for (const utilisation of sequence.utilisations) {
          if (utilisation.id === utilisationId) return utilisation;
        }
      }
    }
  }
  throw new NotFoundException('Utilisation not found!');
}

/** Extraction of common utilisation attributes regardless of the type of sequence. */
function getStartUtilisation(
  startConfiguration: StructuredStartConfigurationType,
  facilityId: string,
  sequenceId: string
): {
  utilisation_date?: string;
  utilisation_amount?: number;
} {
  const regularUtilisation = startConfiguration.utilisations?.[facilityId]?.[sequenceId];
  if (regularUtilisation !== undefined) {
    return {
      utilisation_date: regularUtilisation.utilisation_date,
      utilisation_amount: regularUtilisation.utilisation_amount,
    };
  }

  const drawdownUtilisation = startConfiguration.drawdown?.[facilityId]?.[sequenceId];
  if (drawdownUtilisation !== undefined) {
    return {
      utilisation_date: drawdownUtilisation.drawdown_date,
      utilisation_amount: drawdownUtilisation.drawdown_amount,
    };
  }

  return {};
}

/**
 * Get all references across a loan to a facility in a human readable form.
 *
 * Remark that this doesn't include start-configuration values like drawdowns and
 * utilisations.
 */
const getReferencesToFacility = (loan: StructuredAgreementType, facilityId: string) => {
  const references: string[] = [];

  if (loan.legal_entity?.type === 'facility-link' && loan.legal_entity.facility_id === facilityId) {
    references.push('Selected as lead borrower');
  }

  const crossCollateralized = Object.values(loan.securities_and_guarantees ?? {}).flatMap(v => v.crossCollateralized);
  if (crossCollateralized.some(cc => cc.tag === 'facility' && cc.id === facilityId)) {
    references.push('Cross-collateralized in Securities and Guarantees');
  }

  const reportingEventLinks = loan.reporting_events
    .flatMap(re => re.collections?.map(c => ({ reportedAgainst: c.reportedAgainst, reportingEvent: re })))
    .filter(l => l?.reportedAgainst?.type === 'selected-facilities' && l.reportedAgainst.ids?.includes(facilityId));
  references.push(...reportingEventLinks.map(l => `Selected in reporting event ${l!.reportingEvent.name}`));

  if (
    loan.bank_account_restrictions?.accounts?.some(
      ba => ba.legalEntity?.tag === 'facility' && ba.legalEntity.id === facilityId
    )
  ) {
    references.push('Legal entity of a Restricted Bank Account');
  }

  return references;
};

/**
 * Get all references across a loan to a property.
 */
const getReferencesToProperty = (loan: StructuredAgreementType, propertyId: string) => {
  const references: string[] = [];

  const crossCollateralized = Object.values(loan.securities_and_guarantees ?? {}).flatMap(v => v.crossCollateralized);
  if (crossCollateralized.some(cc => cc.tag === 'property' && cc.id === propertyId)) {
    references.push('Cross-collateralized in Securities and Guarantees');
  }

  return references;
};

/**
 * Map from tranche id to name of tranche.
 *
 * The map contains entries for both construction tranches and other tranches.
 */
const getTrancheNamesMap = (sequence: StructuredSequenceType): { [trancheId: string]: string } => {
  const [id0, id1] = getAllTranches(sequence).map(tranche => tranche.id);
  return id1 === undefined
    ? {
        [id0]: 'Terms Tranche',
      }
    : {
        [id0]: 'Construction Tranche',
        [id1]: 'Terms Tranche',
      };
};

export const loanConfig = {
  findFacility,
  findSequence,
  findSequenceInLoan,
  findTranche,
  getAgentId,
  getAllSequencesIds,
  getAllTranches,
  getBorrowerId,
  getCollectionEvents,
  getCommitmentAmount,
  getConstructionTerms,
  getConstructionOrRegularTranche,
  getFacility,
  getFacilityBySequenceId,
  getFacilityTotalCommitment,
  getFund,
  getGovernmentIds,
  getInformationUndertaking,
  getLeadBorrower,
  getLevel,
  getLevelFromSequence,
  getLoanByState,
  getLoanLevel,
  getLoanParties,
  getMetric,
  getNocParties,
  getOriginalLendersIds,
  getProperty,
  getPropertyOfFacility,
  getReferencesToFacility,
  getReferencesToProperty,
  getReportingEvent,
  getSequence,
  getSequenceInLoan,
  getStartConfigurationByState,
  getStartUtilisation,
  getTerms,
  getTotalCommitment,
  getTranche,
  getTrancheNamesMap,
  getUtilisation,
  isNewAmendmentSequence,
  isOwnedByCompany,
  isRunning,
  isStarted,
  lenderIds,
};
