import * as Yup from 'yup';
import { array, number } from 'yup';
import {
  informationUndertakingQualifications,
  scheduleAlignments,
  ScheduleFirstDateAt,
  ScheduleTerminationDateAt,
} from '../covenants/InformationUndertakingSpecType';
import {
  consequenceOperatorTypes,
  consequenceTypes,
  loanMetricClassTypes,
  loanMetricUnits,
  proofDocuments,
  qualifications,
  reportedAgainstTypes,
  sourceOperators,
} from '../covenants/LoanMetricType';
import { reportingEventCollectionIntervals } from '../covenants/ReportingEventConfigType';
import { interestPeriodTypes } from '../loan/InterestPeriodType';
import { bankAccountRestrictionsSchema } from '../structured-loan/BankAccountRestrictions';
import { distributionRestrictionsSchema } from '../structured-loan/DistributionRestrictions';
import { SLStartSchedulesUpdateEvent, UpdateLoanDistributionsAndAccounts } from '../structured-loan/SLEventType';
import { StructuredLoanState } from '../structured-loan/structured-loan-state';
import { AmortisationTypeOptions } from './AmortisationTypeOptions';
import {
  AmountNumber,
  NumberStripEmpty,
  ObjectStripUnknown,
  OptionalDate,
  Percentage3Digits,
  RequiredArray,
  RequiredDate,
  RequiredMapOf,
  TextStripEmpty,
} from './atoms';
import {
  literal,
  NonEmptyString,
  NonNegativeDecimal,
  NonNegativeInteger,
  NonNegativeNumber,
  OptionalDateDay,
  RsFileValidation,
  sumTypeOptional,
  sumTypeRequired,
} from './base';
import { BookingModelValidation } from './BookingModelValidation';
import { CountriesOptions } from './countries';
import { SLUpdateDocumentsAndSecuritiesValidation } from './loan-documents-validation';
import { SLUpdateLoanFeesValidation } from './loan-fees-validation';
import {
  AmortisationBase,
  AmortisationFrequency,
  ApplicableLaw,
  Currency,
  DayCountConventions,
  getValidation,
  InterestRatePeriod,
  LoanProperties,
  MarketValueEstByOptions,
  optionStripEmpty,
  SLHolidayCalendar,
} from './options';
import { SlDateRollingValidation } from './SLDateRollingValidation';
import { SLInterestPeriodAlignmentValidation } from './SLInterestPeriodAlignmentValidation';
import { SLInterestRateValidation } from './SLInterestRateValidation';
import { LoanStructureValidation } from './SLLoanStructureValidation';
import { SLMaturityValidation } from './SLMaturityValidation';

const EmptyObject = ObjectStripUnknown({});

const CcBinding = TextStripEmpty.max(20).matches(
  /^[a-z0-9_-]*$/i,
  'CC Binding must include only alphanumeric characters, underscores, or dashes.'
);

const ExternalId = TextStripEmpty.max(32).matches(
  /^[a-z0-9_-]*$/i,
  'External ID must include only alphanumeric characters, underscores, or dashes.'
);

const LegalEntity = ObjectStripUnknown({
  name: TextStripEmpty,
  spv_registration_number: TextStripEmpty,
  external_id: ExternalId,
});

const CapTableEntry = ObjectStripUnknown({
  lender: TextStripEmpty,
  amount: NumberStripEmpty.min(0).label('Lender Commitment'),
});

const NewProperty = ObjectStripUnknown({
  name: TextStripEmpty.required('Name is required'),
});

const NewSequence = ObjectStripUnknown({
  utilisations: Yup.array(EmptyObject),
  construction: EmptyObject,
  tranches: RequiredArray(EmptyObject),
});

const NewFacility = ObjectStripUnknown({
  properties: RequiredArray(NewProperty),
  sequences: RequiredArray(NewSequence),
});

export const SLNewEventValidation = ObjectStripUnknown({
  type: literal('new'),
  agreement: ObjectStripUnknown({
    loan_name: TextStripEmpty.required('Loan Name is required'),
    owner_company_id: TextStripEmpty,
    quantum_currency: optionStripEmpty(Currency),
    borrower_company_id: TextStripEmpty,
    agent: TextStripEmpty,
  }),
  facilities: RequiredArray(NewFacility),
});

const ConsequenceValidation = ObjectStripUnknown({
  operator: optionStripEmpty(consequenceOperatorTypes),
  operand: TextStripEmpty,
  consequence: optionStripEmpty(consequenceTypes),
});

const SourceOperandValidation = sumTypeOptional('type', {
  constant: ObjectStripUnknown({ type: literal('constant'), value: TextStripEmpty }),
  metric: ObjectStripUnknown({ type: literal('metric'), value: TextStripEmpty }),
});

const SourceFormulaValidation = ObjectStripUnknown({
  lhs: SourceOperandValidation,
  operator: optionStripEmpty(sourceOperators),
  rhs: SourceOperandValidation,
});

const LoanMetricSourceValidation = sumTypeOptional('type', {
  raw: ObjectStripUnknown({
    type: literal('raw'),
  }),
  formula: ObjectStripUnknown({
    type: literal('formula'),
    formula: SourceFormulaValidation,
  }),
  calculated: ObjectStripUnknown({
    type: literal('calculated'),
  }),
});

const LoanMetricPeriodValidation = sumTypeOptional('type', {
  sot: ObjectStripUnknown({ type: literal('sot') }),
  pit: ObjectStripUnknown({ type: literal('pit') }),
});

export const AgreementMetricValidation = ObjectStripUnknown({
  name: TextStripEmpty.required('Name is required'),
  description: TextStripEmpty,
  definition: TextStripEmpty,
  unit: optionStripEmpty(loanMetricUnits),
  source: LoanMetricSourceValidation,
  period: LoanMetricPeriodValidation,
  qualifications: optionStripEmpty(qualifications),
  class: optionStripEmpty(loanMetricClassTypes),
  proofDocument: optionStripEmpty(proofDocuments),
  ccBinding: CcBinding,
  consequences: RequiredArray(ConsequenceValidation),
  reportedAgainst: optionStripEmpty(reportedAgainstTypes),
});

export const SLAddMetricEventValidation = ObjectStripUnknown({
  type: literal('add-metric-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  metrics: ObjectStripUnknown({
    metricSelected: AgreementMetricValidation,
    metricFormulaLeft: AgreementMetricValidation,
    metricFormulaRight: AgreementMetricValidation,
  }),
});

export const CompanyAddressTypeValidation = ObjectStripUnknown({
  country: TextStripEmpty.required('Country is required'),
  zipCode: TextStripEmpty.required('Zip Code is required'),
  city: TextStripEmpty.required('City is required'),
  street: TextStripEmpty.required('Street is required'),
});
export const UpdateCustomPlatformInteractionsValidation = ObjectStripUnknown({
  'receive-interest-fixing-notification': TextStripEmpty,
  'email-for-interest-fixing-notification': TextStripEmpty,
  'receive-payment-instruction': TextStripEmpty,
  'email-for-payment-instruction': TextStripEmpty,
  'company-communication-address': CompanyAddressTypeValidation,
  overwrite: Yup.boolean(),
  isNoc: Yup.boolean(),
  companyName: TextStripEmpty,
});

export const SLUpdateCustomPlatformInteractionsValidation = ObjectStripUnknown({
  type: literal('custom-notifications-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  platformInteractions: RequiredMapOf(UpdateCustomPlatformInteractionsValidation),
});

export const SLRemoveMetricEventValidation = ObjectStripUnknown({
  type: literal('remove-metric-update'),
  metricId: TextStripEmpty.required(),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLAddInformationUndertakingEventValidation = ObjectStripUnknown({
  type: literal('add-information-undertaking-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLRemoveInformationUndertakingEventValidation = ObjectStripUnknown({
  type: literal('remove-information-undertaking-update'),
  informationUndertakingId: TextStripEmpty.required(),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLAddReportingEventEventValidation = ObjectStripUnknown({
  type: literal('add-reporting-event-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLRemoveReportingEventEventValidation = ObjectStripUnknown({
  type: literal('remove-reporting-event-update'),
  reportingEventId: TextStripEmpty.required(),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

const LegalEntityFundValidation = ObjectStripUnknown({
  type: literal('fund'),
  name: TextStripEmpty,
  spv_registration_number: TextStripEmpty,
  external_id: ExternalId,
});

const LegalEntityFacilityLinkValidation = ObjectStripUnknown({
  type: literal('facility-link'),
  facility_id: TextStripEmpty.required(),
});

export const SLAgreementPartiesValidation = ObjectStripUnknown({
  loan_name: TextStripEmpty.required('Loan Name is required'),
  owner_company_id: TextStripEmpty,
  quantum_currency: optionStripEmpty(Currency),
  agreement_date: TextStripEmpty,
  purpose: ObjectStripUnknown({
    type: optionStripEmpty(['acquisition', 're-finance', 'other']),
    text: TextStripEmpty,
  }),
  applicable_law: optionStripEmpty(ApplicableLaw),
  external_id: ExternalId,
  borrower_company_id: TextStripEmpty,
  agent: TextStripEmpty,
  guarantor_name: TextStripEmpty,
  legal_entity: sumTypeOptional('type', {
    fund: LegalEntityFundValidation,
    'facility-link': LegalEntityFacilityLinkValidation,
  }),
  cap_table: RequiredArray(CapTableEntry),
  joint_venture: Yup.boolean().optional(),
  responsible_user_ids: Yup.array(Yup.string().required()),
});

export const SLAgreementPartiesUpdateEventValidation = ObjectStripUnknown({
  type: literal('agreement-parties-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  agreement: SLAgreementPartiesValidation.required(),
});

export const LegalEntityValidation = ObjectStripUnknown({
  name: TextStripEmpty,
  spv_registration_number: TextStripEmpty,
  external_id: ExternalId,
});

export const FacilityIntroValidation = ObjectStripUnknown({
  legal_entity: LegalEntityValidation,
});

export const AllocateLoanAmountUpdateEventValidation = ObjectStripUnknown({
  type: literal('allocated-loan-amounts-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  version: Yup.number().required(),
  allocatedLoanAmounts: RequiredMapOf(NonNegativeDecimal.optional()),
});

export const SLFacilityIntroEventValidation = ObjectStripUnknown({
  type: literal('facility-intro-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  version: Yup.number().required(),
  facility: FacilityIntroValidation.required(),
});

export const SequenceUtilisationValidation = ObjectStripUnknown({
  quantum_availability_period: TextStripEmpty,
  commitment: NumberStripEmpty.min(0).label('Commitment'),
  quantum_utilisation_request_time: TextStripEmpty,
  quantum_utilisation_request_days: NumberStripEmpty.integer().min(0),
  start_quantum_availability_period: TextStripEmpty,
});

export const SequenceUtilisationUpdateEventValidation = ObjectStripUnknown({
  type: literal('sequence-utilisation-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  utilisationId: TextStripEmpty.required(),
  version: Yup.number().required(),
  utilisation: SequenceUtilisationValidation.required(),
});

export const SequenceUtilisationAmendEventValidation = ObjectStripUnknown({
  type: literal('sequence-utilisation-amend'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  utilisationId: TextStripEmpty.required(),
  version: Yup.number().required(),
  utilisation: SequenceUtilisationValidation.required(),
});

export const SequenceTermSheetValidation = ObjectStripUnknown({
  maturity_payment_of_debt: SLMaturityValidation,
  day_count_convention: optionStripEmpty(DayCountConventions),
  holiday_calendar: Yup.array().of(optionStripEmpty(SLHolidayCalendar)),
  interest_late_payment: Percentage3Digits.min(0),
  interest_rate: SLInterestRateValidation,
  interest_rate_period: optionStripEmpty(InterestRatePeriod),
  interest_period_alignment: SLInterestPeriodAlignmentValidation,
  date_rolling: SlDateRollingValidation,

  interest_capitalised: Yup.boolean(),
  interest_percentage_capitalised: Percentage3Digits.min(0),
  points_interest_percentage_capitalised: Percentage3Digits.min(0),

  amortisation_type: optionStripEmpty(AmortisationTypeOptions),
  amortisation_base: optionStripEmpty(AmortisationBase),
  amortisation_percentage: Percentage3Digits.min(0).max(100),
  amortisation_frequency: optionStripEmpty(AmortisationFrequency),
  first_amortisation_date: OptionalDate(),
  first_longer_interest_period: OptionalDate(),

  subvention: ObjectStripUnknown({
    threshold: NumberStripEmpty.min(0),
    government: TextStripEmpty,
  }),
});

export const SLExternalIdsValidation = ObjectStripUnknown({
  externalId: TextStripEmpty,
  fund: ObjectStripUnknown({
    externalId: TextStripEmpty,
  }),
  facilities: Yup.array(
    ObjectStripUnknown({
      facilityId: TextStripEmpty.required(),
      externalId: TextStripEmpty,
      sequences: Yup.array(
        ObjectStripUnknown({
          sequenceId: TextStripEmpty.required(),
          tranches: Yup.array(
            ObjectStripUnknown({
              trancheId: TextStripEmpty.required(),
              externalId: TextStripEmpty,
            })
          ),
        })
      ),
    })
  ),
});

export const SLSequenceTermSheetUpdateEventValidation = ObjectStripUnknown({
  type: literal('sequence-term-sheet-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  termsId: TextStripEmpty.required(),
  version: Yup.number().required(),
  terms: SequenceTermSheetValidation,
});

export const SLSequenceTermSheetAmendEventValidation = ObjectStripUnknown({
  type: literal('sequence-term-sheet-amend'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  termsId: TextStripEmpty.required(),
  version: Yup.number().required(),
  terms: SequenceTermSheetValidation,
});

export const SequenceConstructionSheetValidation = ObjectStripUnknown({
  commitment: NumberStripEmpty.min(0),
  end_availability_period: TextStripEmpty,
  termination: TextStripEmpty,
  external_id: TextStripEmpty,
  day_count_convention: optionStripEmpty(DayCountConventions),
  holiday_calendar: Yup.array().of(optionStripEmpty(SLHolidayCalendar)),
  interest_late_payment: Percentage3Digits.min(0),
  interest_rate: SLInterestRateValidation,
  interest_rate_period: optionStripEmpty(InterestRatePeriod),
  interest_period_alignment: SLInterestPeriodAlignmentValidation,
  date_rolling: SlDateRollingValidation,
  amortisation_type: optionStripEmpty(AmortisationTypeOptions),
  amortisation_base: optionStripEmpty(AmortisationBase),
  amortisation_percentage: Percentage3Digits.min(0).max(100),
  amortisation_frequency: optionStripEmpty(AmortisationFrequency),
  first_amortisation_date: OptionalDate(),
  subvention: ObjectStripUnknown({
    threshold: NumberStripEmpty.min(0),
    government: TextStripEmpty,
  }),
  interest_capitalised: Yup.boolean(),
  interest_percentage_capitalised: Percentage3Digits.min(0),
  points_interest_percentage_capitalised: Percentage3Digits.min(0),
  expected_termination_date: OptionalDate(),
  first_longer_interest_period: OptionalDate(),
});

export const SLSequenceConstructionSheetUpdateEventValidation = ObjectStripUnknown({
  type: literal('sequence-construction-sheet-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  constructionId: TextStripEmpty.required(),
  version: Yup.number().required(),
  constructionValues: SequenceConstructionSheetValidation,
});

export const SLSequenceConstructionSheetAmendEventValidation = ObjectStripUnknown({
  type: literal('sequence-construction-sheet-amend'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  constructionId: TextStripEmpty.required(),
  version: Yup.number().required(),
  constructionValues: SequenceConstructionSheetValidation,
});

const amountScheduleValidation = RequiredArray(
  ObjectStripUnknown({
    periodIndex: Yup.number().required(),
    value: Yup.number().min(0).required(),
  })
);

const fractionScheduleValidation = RequiredArray(
  ObjectStripUnknown({
    periodIndex: Yup.number().required(),
    value: Yup.number().min(0).max(1).required(),
  })
);

const trancheSchedulesValidation = RequiredMapOf(
  ObjectStripUnknown({
    subventionCoverage: fractionScheduleValidation,
    interestRate: fractionScheduleValidation,
    amortisationAmount: amountScheduleValidation,
  }).required()
);

export const SLStartSchedulesUpdateEventValidation = ObjectStripUnknown<SLStartSchedulesUpdateEvent>({
  type: literal('start-schedules-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
  version: Yup.number().required(),
  utilisation_date: TextStripEmpty.optional(),
  utilisation_amount: NumberStripEmpty.min(0).optional(),
  trancheSchedules: trancheSchedulesValidation,
});

export const SLStartBookingModelUpdateEventValidation = ObjectStripUnknown({
  type: literal('start-booking-model-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  version: Yup.number().required(),
  booking_model: BookingModelValidation,
});

export const SLStartBookingModelAmendEventValidation = ObjectStripUnknown({
  type: literal('start-booking-model-amend'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  version: Yup.number().required(),
  booking_model: BookingModelValidation,
});

export const SLStructureUpdateEventValidation = ObjectStripUnknown({
  type: literal('loan-structure-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  facilities: RequiredArray(LoanStructureValidation),
});

export const SLStructureAmendEventValidation = ObjectStripUnknown({
  type: literal('loan-structure-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  facilities: RequiredArray(LoanStructureValidation),
});

export const SLAgreementMetricUpdateEventValidation = ObjectStripUnknown({
  type: literal('agreement-metric-update'),
  loanId: TextStripEmpty.required(),
  metricId: TextStripEmpty.required(),
  version: Yup.number().required(),
  metric: AgreementMetricValidation.required(),
});

export const SLAgreementMetricAmendEventValidation = ObjectStripUnknown({
  type: literal('agreement-metric-amend'),
  loanId: TextStripEmpty.required(),
  metricId: TextStripEmpty.required(),
  version: Yup.number().required(),
  metric: AgreementMetricValidation.required(),
});

const InformationCollectionValidation = ObjectStripUnknown({
  id: TextStripEmpty,
});

const InterestPeriodValidation = optionStripEmpty(interestPeriodTypes);

const InformationUndertakingScheduleValidation = sumTypeRequired('type', {
  recurring: Yup.object({
    type: literal('recurring'),
    startDateAt: Yup.string(),
    startDate: Yup.string().when('startDateAt', {
      is: ScheduleFirstDateAt.CUSTOM_DATE,
      then: Yup.string().required('Custom start date is required'),
    }),
    period: InterestPeriodValidation,
    alignment: optionStripEmpty(scheduleAlignments),
    terminationDateAt: Yup.string(),
    terminationDate: Yup.string().when('terminationDateAt', {
      is: ScheduleTerminationDateAt.CUSTOM_DATE,
      then: Yup.string().required('Custom termination date is required'),
    }),
  }),
  'non-recurring': Yup.object({
    type: literal('non-recurring'),
    checkDate: OptionalDate(),
    period: InterestPeriodValidation,
  }),
});

const FundChoiceValidation = ObjectStripUnknown({ type: literal('fund') });

const AllFacilitiesChoiceValidation = ObjectStripUnknown({ type: literal('all-facilities') });

const AggregatedFacilitiesChoiceValidation = ObjectStripUnknown({ type: literal('aggregated-facilities') });

const SelectedFacilitiesChoiceValidation = ObjectStripUnknown({
  type: literal('selected-facilities'),
  ids: Yup.array(TextStripEmpty),
});

const IUReportedAgainstChoiceValidation = sumTypeOptional('type', {
  'all-facilities': AllFacilitiesChoiceValidation,
  'selected-facilities': SelectedFacilitiesChoiceValidation,
});

const IUReportedAgainstValidation = sumTypeOptional('type', {
  legal_entity: ObjectStripUnknown({
    type: literal('legal_entity'),
    fund: Yup.boolean().required(),
    choice: IUReportedAgainstChoiceValidation,
  }),
  property: ObjectStripUnknown({
    type: literal('property'),
    choice: IUReportedAgainstChoiceValidation,
  }),
});

const REReportedAgainstValidation = sumTypeOptional('type', {
  fund: FundChoiceValidation,
  'all-facilities': AllFacilitiesChoiceValidation,
  'aggregated-facilities': AggregatedFacilitiesChoiceValidation,
  'selected-facilities': SelectedFacilitiesChoiceValidation,
});

const DeadlineValidation = NumberStripEmpty.integer('Deadline must be a full number of days').min(
  0,
  'Deadline cannot be negative'
);

const QualificationValidation = optionStripEmpty(informationUndertakingQualifications);

export const AgreementInformationUndertakingValidation = ObjectStripUnknown({
  name: TextStripEmpty.required('Name is required'),
  schedule: InformationUndertakingScheduleValidation,
  deadline: DeadlineValidation,
  qualification: QualificationValidation,
  collections: Yup.array(InformationCollectionValidation), // Collects are optional.
  reportedAgainst: IUReportedAgainstValidation,
});

export const SLAgreementInformationUndertakingUpdateEventValidation = ObjectStripUnknown({
  type: literal('agreement-information-undertaking-update'),
  loanId: TextStripEmpty.required(),
  iuId: TextStripEmpty.required(),
  version: Yup.number().required(),
  information_undertaking: AgreementInformationUndertakingValidation.required(),
});

const ReportingEventCollectionConfigDraftValidation = ObjectStripUnknown({
  metricId: TextStripEmpty.required('ID is required'),
  qualification: QualificationValidation,
  interval: optionStripEmpty(reportingEventCollectionIntervals),
  reportedAgainst: REReportedAgainstValidation,
  recurrence: InterestPeriodValidation,
});

export const SLReportingEventConfigDraftValidation = ObjectStripUnknown({
  name: TextStripEmpty.required('Name is required'),
  ccTemplate: TextStripEmpty,
  qualification: QualificationValidation,
  schedule: InformationUndertakingScheduleValidation,
  deadline: DeadlineValidation,
  collections: RequiredArray(ReportingEventCollectionConfigDraftValidation),
  documents: Yup.array(
    ObjectStripUnknown({
      id: TextStripEmpty.required('ID is required'),
      name: TextStripEmpty.required('Name is required'),
    }).required()
  ).notRequired(),
});

export const SLAgreementReportingEventUpdateEventValidation = ObjectStripUnknown({
  type: literal('agreement-reporting-event-update'),
  loanId: TextStripEmpty.required(),
  reId: TextStripEmpty.required(),
  version: Yup.number().required(),
  reporting_event: SLReportingEventConfigDraftValidation.required(),
});

export const SLRunLoanEventValidation = ObjectStripUnknown({
  type: literal('run-structured-loan-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLLoanPropertyTenantValidation = ObjectStripUnknown({
  tenant_name: TextStripEmpty.nullable(true),
  tenant_rental_income: number()
    .positive('Rental Income must be a positive number')
    .max(100, 'Maximum allowed Rental Income percentage is 100')
    .nullable(true),
  tenant_rental_duration: number().positive('Contract Duration must be a positive number').nullable(true),
});

export const SLLoanPropertyAttributesValidation = ObjectStripUnknown({
  property_address: TextStripEmpty.nullable(true),
  property_type: getValidation(LoanProperties, true),
  square_meters: NonNegativeInteger.nullable(true),
  tenants: array().of(SLLoanPropertyTenantValidation).nullable(true),
});

export const AgreementPropertyValidation = ObjectStripUnknown({
  name: TextStripEmpty.required('Name is required'),
  property_address: TextStripEmpty.nullable(true),
  property_zip: TextStripEmpty.nullable(true),
  gallery: array().of(RsFileValidation).nullable(true),
  property_country: getValidation(CountriesOptions, true),
  property_city: TextStripEmpty.nullable(true),
  property_code: TextStripEmpty.nullable(true),
  property_external_id: TextStripEmpty.nullable(true),
  property_market_currency: optionStripEmpty(Currency).nullable(true),
  property_original_market_value: AmountNumber().nullable(true),
  property_original_valuator: getValidation(MarketValueEstByOptions, true),
  property_original_valuator_others: NonEmptyString.nullable(true),
  property_current_market_value_date: OptionalDateDay('Invalid Market Value Estimation Date').nullable(true),
  construction_finish_date: OptionalDateDay('Invalid Construction Finish Date').nullable(true),
  upload_material: array().of(RsFileValidation).nullable(true),
  properties: array().of(SLLoanPropertyAttributesValidation).nullable(true),
  sold: Yup.boolean().optional(),
  release_price_multiplier: NonNegativeNumber.optional(),
});

export const SLAgreementPropertyEventValidation = ObjectStripUnknown({
  type: literal('agreement-property-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  propertyId: TextStripEmpty.required(),
  version: Yup.number().required(),
  property: AgreementPropertyValidation.required(),
});

export const SLUpdateStateEventValidation = ObjectStripUnknown({
  type: literal('state-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  state: optionStripEmpty(Object.values(StructuredLoanState)).required(),
});

export const SLExternalIdEventValidation = ObjectStripUnknown({
  type: literal('external-ids-update'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  externalIds: SLExternalIdsValidation.required(),
});

// ************** AMEND VALIDATIONS **********
export const SLAmendExternalIdsValidation = SLExternalIdsValidation;

export const SLPropertyDetailsAmendValidation = Yup.array(
  ObjectStripUnknown({
    id: TextStripEmpty.required(),
    properties: Yup.array(ObjectStripUnknown({ property_type: TextStripEmpty })),
  })
);

export const SLExternalIdAmendEventValidation = ObjectStripUnknown({
  type: literal('external-ids-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  externalIds: SLExternalIdsValidation.required(),
});

export const SLLoanAmendStartEventValidation = ObjectStripUnknown({
  type: literal('start-loan-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLLoanAmendCancelEventValidation = ObjectStripUnknown({
  type: literal('cancel-loan-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLLoanAmendCommitEventValidation = ObjectStripUnknown({
  type: literal('commit-loan-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
});

export const SLAgreementPartiesAmendEventValidation = ObjectStripUnknown({
  type: literal('agreement-parties-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  agreement: SLAgreementPartiesValidation.required(),
});

export const SLAgreementPropertyAmendEventValidation = ObjectStripUnknown({
  type: literal('agreement-property-update'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  propertyId: TextStripEmpty.required(),
  version: Yup.number().required(),
  property: Yup.reach(SLAgreementPropertyEventValidation, 'property'),
});

export const SLFacilityIntroAmendEventValidation = ObjectStripUnknown({
  type: literal('facility-intro-amend'),
  loanId: TextStripEmpty.required(),
  facilityId: TextStripEmpty.required(),
  version: Yup.number().required(),
  facility: Yup.reach(SLFacilityIntroEventValidation, 'facility'),
});

export const SLAmendCustomPlatformInteractionsValidation = ObjectStripUnknown({
  type: literal('custom-notifications-amend'),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  platformInteractions: Yup.reach(SLUpdateCustomPlatformInteractionsValidation, 'platformInteractions'),
});

export const SLMigrateStructuredLoanEventValidation = ObjectStripUnknown({
  type: literal('migrate-structured-loan'),
  loan: ObjectStripUnknown({})
    .required()
    .test('migrate-structured-loan', 'Migrate events are not allowed', () => false),
});

export const SLUpdateDistributionAndAccountsValidation = ObjectStripUnknown<UpdateLoanDistributionsAndAccounts>({
  type: Yup.string().oneOf(['loan-distribution-and-accounts-update']).required(),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  bankAccountRestrictions: bankAccountRestrictionsSchema.optional(),
  distributionRestrictions: distributionRestrictionsSchema.optional(),
});

export const SLEventValidation = sumTypeRequired('type', {
  'add-metric-update': SLAddMetricEventValidation,
  'remove-metric-update': SLRemoveMetricEventValidation,
  'add-information-undertaking-update': SLAddInformationUndertakingEventValidation,
  'remove-information-undertaking-update': SLRemoveInformationUndertakingEventValidation,
  'add-reporting-event-update': SLAddReportingEventEventValidation,
  'remove-reporting-event-update': SLRemoveReportingEventEventValidation,
  'agreement-parties-update': SLAgreementPartiesUpdateEventValidation,
  'allocated-loan-amounts-update': AllocateLoanAmountUpdateEventValidation,
  'facility-intro-update': SLFacilityIntroEventValidation,
  'sequence-utilisation-update': SequenceUtilisationUpdateEventValidation,
  'sequence-utilisation-amend': SequenceUtilisationAmendEventValidation,
  'sequence-term-sheet-update': SLSequenceTermSheetUpdateEventValidation,
  'sequence-term-sheet-amend': SLSequenceTermSheetAmendEventValidation,
  'sequence-construction-sheet-update': SLSequenceConstructionSheetUpdateEventValidation,
  'sequence-construction-sheet-amend': SLSequenceConstructionSheetAmendEventValidation,
  'start-schedules-update': SLStartSchedulesUpdateEventValidation,
  'start-booking-model-update': SLStartBookingModelUpdateEventValidation,
  'start-booking-model-amend': SLStartBookingModelAmendEventValidation,
  'loan-structure-update': SLStructureUpdateEventValidation,
  'loan-structure-amend': SLStructureAmendEventValidation,
  'agreement-metric-update': SLAgreementMetricUpdateEventValidation,
  'agreement-metric-amend': SLAgreementMetricAmendEventValidation,
  'agreement-information-undertaking-update': SLAgreementInformationUndertakingUpdateEventValidation,
  'agreement-reporting-event-update': SLAgreementReportingEventUpdateEventValidation,
  'run-structured-loan-update': SLRunLoanEventValidation,
  'agreement-property-update': SLAgreementPropertyEventValidation,
  'state-update': SLUpdateStateEventValidation,
  'external-ids-update': SLExternalIdEventValidation,
  'custom-notifications-update': SLUpdateCustomPlatformInteractionsValidation,
  'loan-fees-update': SLUpdateLoanFeesValidation,
  'loan-documents-and-securities-update': SLUpdateDocumentsAndSecuritiesValidation,
  'loan-distribution-and-accounts-update': SLUpdateDistributionAndAccountsValidation,
  'start-loan-amend': SLLoanAmendStartEventValidation,
  'cancel-loan-amend': SLLoanAmendCancelEventValidation,
  'commit-loan-amend': SLLoanAmendCommitEventValidation,
  'external-ids-amend': SLExternalIdAmendEventValidation,
  'agreement-parties-amend': SLAgreementPartiesAmendEventValidation,
  'agreement-property-amend': SLAgreementPropertyAmendEventValidation,
  'facility-intro-amend': SLFacilityIntroAmendEventValidation,
  'custom-notifications-amend': SLAmendCustomPlatformInteractionsValidation,
  'migrate-structured-loan': SLMigrateStructuredLoanEventValidation,
});

export const LoanStructureFacilityValidation = ObjectStripUnknown({
  facilities: Yup.array(LoanStructureValidation),
});

export const SLInitiateDrawdown = ObjectStripUnknown({
  comment: TextStripEmpty,
  drawnAmount: NumberStripEmpty.min(0).label('Drawdown Amount').required(),
  drawnDate: RequiredDate(),
  documents: array().of(RsFileValidation).nullable(true),
});
