import { defineApi } from '../common/api-definition';
import { SequenceEventType, TransactionEventType } from '../loan/EventType';
import { SequenceNextCommandType, TransactionNextCommandType } from '../loan/NextCommandType';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';

export type PaymentEventsView = {
  nextItems: NextPaymentEventView[];
  pastItems: PastPaymentEventView[];
};

export type NextPaymentEventView = {
  common: CommonPaymentEventView;
  nextCommand: SequenceNextCommandType<TransactionNextCommandType>;
};

export type PastPaymentEventView = {
  common: CommonPaymentEventView;
  event: SequenceEventType<TransactionEventType>;
};

export type CommonPaymentEventView = {
  loanId: string;
  loanName: string;
  facilityName: string;
  sequenceName: string;
  currency: string;
};

export type PaymentEventsViewRequest = {
  loanIds: string[];
};

export const paymentEventsViewValidation = ObjectStripUnknown<PaymentEventsViewRequest>({
  loanIds: RequiredArray(NonEmptyString),
}).required();

export const paymentEventsViewApi = defineApi<{
  body: PaymentEventsViewRequest;
  response: PaymentEventsView;
}>({
  url: 'structured-loans/payment-events-view',
  method: 'post',
});
